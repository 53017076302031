.file-list-item{
  width: 101%;
  padding: 0px !important;
  
  .container {
    padding: 5px;
    flex-flow: row;
    cursor: pointer;

    &:hover {
      background: #e3f4fc;
    }

    .container-image {
      width: 30px;
      height: 30px;
      border: 1px solid #dedede;
      background: #f3f4f6;
  
      .ant-image {
        height: 100%;
        display: flex;
        align-items: center;
      }
    
      .ant-image-img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
        margin: auto;
      }
    }
  
    .container-label {
      margin-left: 5px;
    }
  }
}

@layout-header-background: #4b2e6a;